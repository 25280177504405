export function handleListItemSlider() {
    // SERVICE PAGE LOGO SLIDER //
    const items = document.querySelector('.list-item-icon.--mobile .swiper')

    if (!items) return

    const listItemIcons = new Swiper(items, {
        // loop: true,
        slidesPerView: 1.3,
        spaceBetween: 18,
        autoHeight: true,
        slideToClickedSlide: true,
        slidesOffsetBefore: 16,
        slidesOffsetAfter: 16,
    })
}

export function handleFloatingBar() {
    const floatingBar = document.querySelector('#industries__float-bar')
    if (!floatingBar) return

    // Elementos para mostrar/ocutar la barra al interseccionar
    const idShowRef = floatingBar.dataset.show
    const idHideRef = floatingBar.dataset.hide
    const showElement = document.querySelector(`#${idShowRef}`)
    const hideElement = document.querySelector(`#${idHideRef}`)

    if (!showElement && !hideElement) return

    let lastScrollPosition = 0

    window.addEventListener('scroll', () => {
        const currentScrollPosition = window.pageYOffset
        if (currentScrollPosition < lastScrollPosition) {
            floatingBar.classList.remove('visible')
        } else {
            const isBetweenShowAndHide = currentScrollPosition > showElement.offsetTop - 400 && currentScrollPosition < hideElement.offsetTop - 400
            if (isBetweenShowAndHide) {
                floatingBar.classList.add('visible')
            } else {
                floatingBar.classList.remove('visible')
            }
        }
        lastScrollPosition = currentScrollPosition
    })

    // Scroll To
    const scrollData = floatingBar.dataset.scroll

    if (!scrollData) return
    const html = document.querySelector('html')

    const rootMarginData = floatingBar.dataset.margin || 0

    const button = floatingBar.querySelector('.btn-box')
    const scrollBehavior = window.getComputedStyle(html).scrollBehavior

    button.addEventListener('click', () => {
        const elementToScroll = document.querySelector(`#${scrollData}`) || document.querySelector(`.${scrollData}`)
        const topPos = elementToScroll.getBoundingClientRect().top + window.pageYOffset
        gsap.to(window, {
            scrollTo: {
                y: topPos + parseInt(rootMarginData),
                autoKill: false,
            },
            duration: 1.5,
            ease: 'Power4.easeOut',
            onStart: () => (html.style.scrollBehavior = 'auto'), // Necesario para que funcione el ease
            onComplete: () => (html.style.scrollBehavior = scrollBehavior || 'auto'),
        })
    })
}

export function industryHeaderBackgroundAnimation() {
    

    if (document.querySelector('.industries__hero.--automotive')) {
        const timming = 4
        const timeline = gsap.timeline({ repeat: -1 })
        timeline.to('.industries__hero.--automotive', {
            duration: timming,
            ease: 'power2.inOut',
            backgroundPositionY: '80%',
            backgroundSize: '190% 120%',
        })
        timeline.to('.industries__hero.--automotive', {
            duration: timming,
            backgroundPositionY: '50%',
            ease: 'power2.inOut',
            backgroundSize: '150% 120%',
        })
        timeline.to('.industries__hero.--automotive', {
            duration: timming,
            backgroundPositionY: '90%',
            ease: 'power2.inOut',
            backgroundSize: '100% 120%',
        })
    }
    else if (document.querySelector('.industries__hero')) {
        const timming = 4
        const timeline = gsap.timeline({ repeat: -1 })
        timeline.to('.industries__hero', {
            duration: timming,
            ease: 'power2.inOut',
            backgroundPositionY: '80%',
            backgroundSize: '160% 120%',
        })
        timeline.to('.industries__hero', {
            duration: timming,
            backgroundPositionY: '1%',
            ease: 'power2.inOut',
            backgroundSize: '120% 120%',
        })
        timeline.to('.industries__hero', {
            duration: timming,
            backgroundPositionY: '40%',
            ease: 'power2.inOut',
            backgroundSize: '100% 120%',
        })
    }
    return false
}

export function digitalExperienceAnimation(){
    const boxContainer = document.querySelector('.industries__digital-experience__boxes-container');
    
    //Desktop
    if (boxContainer && window.innerWidth > 768) {
        const imagesContainer = document.querySelector('.industries__digital-experience__images-container');
        const boxes = boxContainer.querySelectorAll('.industries__digital-experience__box');
        const images = imagesContainer.querySelectorAll('.industries__digital-experience__image');
        const circle = document.querySelector(".industries__digital-experience__circle-progress");
        const lastChild = boxContainer.lastElementChild;
        
        let margin = 0;
        let boxesArray = [];
        let imagesArray = [];

        function responsiveMarginAnimation(){
            if (lastChild.getBoundingClientRect().height < 400 && window.innerWidth >= 1440 ) {
                margin = 500 - lastChild.getBoundingClientRect().height
                lastChild.style.marginBottom = `${margin}px`;
            }
            if (lastChild.getBoundingClientRect().height < 400 && window.innerWidth <= 1024 ) {
                margin = 375 - lastChild.getBoundingClientRect().height
                lastChild.style.marginBottom = `${margin}px`;
            }
        };
        function addClassesAndCompleteBoxesArray(){
            boxes.forEach((box, index) => {
                box.classList.add(`box-${index}`);
                boxesArray.push({class:`.box-${index}`, height: box.getBoundingClientRect().height})
            })
        };
        function addClassesImagesAndCompleteImagesArray(){
            images.forEach((image, index) => {
                const newIdx = images.length - index - 1; 
                image.classList.add(`img-${newIdx}`);
                image.style.zIndex = newIdx;
                imagesArray.push(`img-${newIdx}`);
            });
        };
        function addBoxOutClassToImage(boxArray) {
            boxArray.forEach((box, index) => {
                if (box.classList.contains('--box-out')) {
                    images[index].classList.add('--img-out');
                } else {
                    images[index].classList.remove('--img-out');
                }
            });
        };
        function circleProgress(progress) {
            let progressCircle = 622 - progress * 622 / 100;
            circle.style.strokeDashoffset = progressCircle;
        };
        function scrollUpdateCircleAndBoxes(){
            window.addEventListener('scroll', ()=>{
                let boxArray = Array.from(boxes);
                addBoxOutClassToImage(boxArray);
            })
        };
        function boxesAnimation(){
            for(let i = 0; i < boxesArray.length - 1; i++){
                gsap.to(boxesArray[i].class, {
                    scrollTrigger: {
                        trigger: boxesArray[i].class,
                        start: "center 220px",
                        end: "center 220px",
                        onEnter: () => {
                            let currentBox = document.querySelector(boxesArray[i].class)
                            currentBox.classList.add('--box-out');
                        },
                        onEnterBack: () => {
                            let currentBox = document.querySelector(boxesArray[i].class)
                            currentBox.classList.remove('--box-out');
                        }
                    }
                })
            }
        };
        function endAnimation(){
            gsap.to(boxesArray[boxesArray.length - 1].class, {
                scrollTrigger: {
                    trigger: boxesArray[boxesArray.length - 1].class,
                    start: "top 320px",
                    end: `top 320px`,
                    onEnter: () => {
                        let hrText = document.querySelector('.industries__digital-experience__hr-text');
                        hrText.classList.add('--hr-text-out');
                    },
                    onEnterBack: () => {
                        let hrText = document.querySelector('.industries__digital-experience__hr-text');
                        hrText.classList.remove('--hr-text-out');
                    },
                },
            })
        };
        function circleAnimation(){
            gsap.to(boxContainer, {
                scrollTrigger: {
                    trigger: boxContainer,
                    start: "top 220px",
                    end: `${boxContainer.getBoundingClientRect().height} center`,
                    scrub: true,
                    onUpdate: function(animation) {
                        const progress = Math.round(animation.progress * 100);
                        circleProgress(progress);
                    },
                },
            })
        };

        responsiveMarginAnimation();
        addClassesAndCompleteBoxesArray();
        addClassesImagesAndCompleteImagesArray();
        scrollUpdateCircleAndBoxes();
        boxesAnimation();
        circleAnimation()
        endAnimation();
    }
    
    //Mobile
    if(boxContainer && window.innerWidth <= 768) {
        const container = document.querySelector('.industries__digital-experience__mobile__boxes-container');
        const circle = document.querySelector(".industries__digital-experience__mobile__circle-progress");

        function circleProgress(progress) {
            let progressCircle = 264 - progress * 264 / 100;
            circle.style.strokeDashoffset = progressCircle;
        };
        function circleAnimationMobile(){
            container.addEventListener('scroll', function() {
                let scrollPosition = container.scrollLeft;
                let contentWidth = container.scrollWidth - container.clientWidth;
                let progress = Math.round((scrollPosition / contentWidth) * 100);
                circleProgress(progress)
            });
        };
        
        circleAnimationMobile();
    }
}