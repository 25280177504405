import { vanillaFade } from "../modules/tools";

class EgoModal {
    constructor({ element, onOpen, onClose, lockScrollOnOpen }) {
        this.modal = element,
        this.lockScrollOnOpen = lockScrollOnOpen || true,
        this.box = element.querySelector('.ego-modal__box'),
        this.content = element.querySelector('.ego-modal__box__body-wrapper'),
        this.closeBtn = element.querySelector('.ego-modal__box__close-btn'),
        this.onOpen = onOpen,
        this.onClose = onClose,
        this.state = false

        const self = this;
        this.closeBtn.addEventListener('click', () => self.close());
        this.modal.addEventListener('click', e => {
            if(e.target && e.target.classList.contains('close-modal')){
                self.close();
            }
        });
    }

    open(callback) {
        this.lockScroll();
        vanillaFade({
            element: this.modal, 
            enter: true, 
            duration: 250, 
            displayType: 'block', 
            callback: callback
        });
        setTimeout(() => {
            this.modal.classList.add('--open');
        }, 20);
        this.state = true;
        if (typeof this.onOpen === 'function') this.onOpen();
    }

    close(callback) {
        vanillaFade({
            element: this.modal, 
            enter: false, 
            duration: 250, 
            displayType: 'block', 
            callback: callback
        });
        this.modal.classList.remove('--open');
        this.lockScroll(false);
        this.state = false;
        if (typeof this.onClose === 'function') this.onClose();
    }

    lockScroll(lock = true) {
        if (!this.lockScrollOnOpen) return false;
        
        let body = document.getElementsByTagName('body')[ 0 ];
        if (lock) body.style.overflow = 'hidden';
        else body.style.removeProperty('overflow');
    }

    inyectContent(content, callback) {
        this.content.innerHTML = content;
        if (typeof callback === 'function') callback();
    }

    darkMode(status) {
        if (status === 'on') this.modal.classList.add('--dark-mode');
        else if (status === 'off') this.modal.classList.remove('--dark-mode');
    }
}

export default EgoModal;