import { docReady, getWindowTop } from "./modules/tools";
import { navbarScrollBehavior, logoBackgroundColorController } from "./modules/header";
import { mottoAnimation, handleSliderAnimationHome } from "./modules/home";
import { handleLogoSlider, handleServiceHeaderAnimation } from "./modules/service";
import { 
    cultureManifestoAnimation, 
    calculateStickyPlanetOffset, 
    inspiringForestIntro,
    inspiringForestCounter,
    inspiringForestSliders,
    imgBoxToggle,
    handleCertifiedBAnimation
} from "./modules/culture";
import { handleTextSlider, handleCareersAnimation, handlePeopleAreSaying, handleTeamClimateAnimation } from "./modules/careers";
import { caseHeaderBackgroundAnimation } from "./modules/cases"
import { defineFooterTheme, subscribeAnimation } from "./modules/footer";
import EgoModal from "./components/ego-modal";
import { handleSlideToyotaArg } from "./modules/cases/toyota-arg";
import { handleTextSliderSantander } from "./modules/cases/santander";
import { handlePrinciplesSlider } from "./modules/cases/osde";
import { getModalContent } from "./modules/modals";
import { initModalForms, searchableRadioButtonsGroup, initSubscribeForm } from "./modules/forms";
import { initConcentricCircles } from "./components/concentric-circles";
import { handleFloatingBar, handleListItemSlider, industryHeaderBackgroundAnimation, digitalExperienceAnimation } from "./modules/industry";
import { heightTitlePostDinamic, heightTitleThreePostDinamic, handlerStaticSubscribe, hanldeBoxClass } from "./modules/journal";

// Main Modal
const egoModal = new EgoModal({
    element: document.getElementById('egoModal'),
    onClose: () => {
        const modalVideoEmbed = document.getElementById('modalVideoEmbed');
        if (modalVideoEmbed) modalVideoEmbed.src = '';
    }
});

document.querySelectorAll('a').forEach(anchor => {
    const href = new URL(anchor.href),
        path = href.pathname.split('/').filter(i => i),
        params = href.searchParams,
        hash = href.hash;
    if (path[0] && path[0] === 'modals') {
        anchor.addEventListener('click', async (e) => {
            e.preventDefault();
            const modalContent = await getModalContent(anchor.href);
            if (modalContent) {
                egoModal.inyectContent(modalContent, () => {
                    initModalForms();
                    searchableRadioButtonsGroup();
                    if (params && params.get('darkmode')) {
                        egoModal.darkMode('on');
                    } else {
                        egoModal.darkMode('off');
                    }
                    egoModal.open();
                });
            }
            else {
                window.location.href = href;
            }
        })
    }
});


// Navbar animation listeners
window.egoHeader.element.addEventListener("mouseover", () => { 
    window.egoHeader.isCurrentlyHovered = true;
    if (window.egoHeader.isFixed && window.innerWidth >= 992) window.egoHeader.animation.reverse();
});
window.egoHeader.element.addEventListener("mouseout", () => { 
    window.egoHeader.isCurrentlyHovered = false;
    if (window.egoHeader.isFixed && window.innerWidth >= 992) {
        window.egoHeader.animation.play();
        window.egoHeader.element.classList.remove('--active');
    } 
});



docReady(() => {
    // Footer
    defineFooterTheme();
    subscribeAnimation();
    initSubscribeForm();

    // Home
    mottoAnimation();
    handleSliderAnimationHome();

    //Services
    handleLogoSlider();
    handleServiceHeaderAnimation();
    
    // Culture
    cultureManifestoAnimation();
    calculateStickyPlanetOffset();
    inspiringForestIntro();
    inspiringForestCounter();
    inspiringForestSliders();
    imgBoxToggle();
    handleCertifiedBAnimation();

    // Careers
    handleTextSlider();
    handleCareersAnimation();
    initConcentricCircles();
    handlePeopleAreSaying();
    handleTeamClimateAnimation();

    //Cases
    caseHeaderBackgroundAnimation();
    handleSlideToyotaArg();
    handleTextSliderSantander();
    handlePrinciplesSlider();

    // Industry
    handleListItemSlider();
    handleFloatingBar();
    industryHeaderBackgroundAnimation();
    digitalExperienceAnimation();

    // Journal
    heightTitlePostDinamic();
    heightTitleThreePostDinamic();
    handlerStaticSubscribe();
    hanldeBoxClass();
});

// Parse emojis
document.querySelectorAll('.emoji').forEach(item => twemoji.parse(item) );

let previousScrollTop = 0;
window.addEventListener('scroll', function(){
    const current = getWindowTop();
    const direction = (previousScrollTop <= current) ? 'down' : 'up';
    previousScrollTop = current;

    navbarScrollBehavior(direction);
    logoBackgroundColorController();
});