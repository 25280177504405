export function handleLogoSlider() {
    // SERVICE PAGE LOGO SLIDER //
    const swiperLogos = document.querySelector(".swiper.swiper-logo");
    const swiperLogosReverse = document.querySelector(
        ".swiper.swiper-logo--reverse"
    );

    if (!(swiperLogos && swiperLogosReverse)) return;

    // Swiper.js config //
    const options = {
        loop: true,
        slidesPerView: 2.5,
        // spaceBetween: 20,
        autoplay: {
            delay: 0,
        },
        allowTouchMove: false,
        speed: 2500,
        breakpoints: {
            576: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3.5,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            },
        },
    };

    new Swiper(".swiper.swiper-logo", options);

    // Reverse slider
    new Swiper(".swiper.swiper-logo--reverse", {
        ...options,
        autoplay: {
            delay: 0,
            reverseDirection: true,
        },
    });
}

export function handleServiceHeaderAnimation() {
    const copy = document.querySelector(".service-hero--copy");
    const mask = document.querySelector(".service-explanation__box");
    const header = document.querySelector(".header");

    if (!(copy && mask && header)) return;
    function getInsetValues(copy, mask) {
        const container = copy.getBoundingClientRect();
        const box = mask.getBoundingClientRect();
        return `inset(\
          ${box.top}px \
          ${box.right - box.width}px \
          ${container.height - (box.top + box.height)}px \
          ${box.left}px)`;
    }

    const insetValues = getInsetValues(copy, mask);


    function resetMenuBackground() {
        header.classList.remove("--dark-mode");
    }

    return gsap.to(copy, {
        scrollTrigger: {
            trigger: header,
            start: "75%",
            scrub: false,
            once: true,
            pin: false,
            markers: false,
        },
        duration: 0.25,
        ease: "power2",
        clipPath: insetValues,
        onComplete: () => resetMenuBackground(),
    });
}
