export function handleSlideToyotaArg() {
    const swiperBox = document.querySelector(
        ".toyota-arg-journey__thumbs.swiper"
    );
    if (!swiperBox) return;

    // Swiper.js config
    new Swiper(swiperBox, {
        autoplay: {
            delay: 2000,
        },
        speed: 250,
        loop: true,
        allowTouchMove: false,
    });
}
