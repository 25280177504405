import { isTouchDevice } from "../modules/device-detection";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// DEFINICION DE VARIABLES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 

//Área destinada al movimiento en X del gráfico y en particular el círculo principal.
var areaX;

//Wrapper de contenido
//Para limitar el movimiento en X del cículo principal.
var wrapper;

//Acá guardaremos el elemento que contiene a la sección
const container = document.querySelector('.concentric-circles');
const containerHeight = container ? container.offsetHeight : null;

//Cantidad de círculos
var circles = 15; 

//Tamaños de círculos S M L
var circleSize = "L"; 

//Buscamos los márgenes del wrapper en relación a la página
var wrapperMargin;

//Definimos los límites de movimiento en X del gráfico
//LEFT para evitar que quede por debajo del texto y complique la legibildiad
var leftLimit;
//RIGHT para que en grandes resoluciones no se vaya más alla del wrapper
var rightLimit;

//Punto en dónde queremos que quede centrado (en el medio de su espacio de movimiento)
var centerX;
var centerY;

//Posicion del container en relacion al viewport
var viewportPos;

//storeamos coordenadas del mouse para el scroll
var mouseX;
var mouseY;

//terminan moviendo a los circulos
var moveX;
var moveY;

//Seteamos si los circulos siguen al mouse, son estáticos, son laterales, o van al top
var circlePosition; // "side" o "top"
var circleInteraction; // "follow", "auto" o "static"




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// RAMILLETE DE FUNCIONES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 



function setCircles(){
    
    var initSize;
    var increment;
    
    switch (circleSize) {
        
        case "L":
            initSize = 180;
            increment = 100;
            break;
        
        case "M":
            initSize = 160;
            increment = 90;
            break;
            
        case "S":
            initSize = 80;
            increment = 40;
            break;
        
    }
    
    for (var i = circles; i > 0; i--) {
        
        //obtenemos un circulo
        var c = document.querySelector(`[data-circle="${i}"]`);
        
        //Definimos el diametro
        var size = initSize + ((i - 1) * increment)
        
        //Aplicamos las dimensiones
        c.style.width = size + "px";
        c.style.height = size + "px";
    }
    
}


function centerCircles(){
    for (var i = circles; i > 0; i--) {
        //obtenemos un circulo
        var c = document.querySelector(`[data-circle="${i}"]`);
        var centering = c.offsetWidth / 2;
        
        //definimos los ejes X e Y de los circulos.
        //Movimiento en Y
        moveY = centerY - centering;
        moveX = (container.offsetWidth / 1.33) - centering;
        
        //Aplicamos las coordenadas
        gsap.to(c, {
            translateX: moveX, 
            translateY: moveY,
            translateZ: 8*i,
            duration: 0, 
            ease: "circ", 
        });
    }
}




function catchMouse (e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
    moveCircles();
}




function checkViewport() {
    
    //Obtenemos la posicion del container. La pasamos a positivo para los cálculos futuros.
    viewportPos = -container.getBoundingClientRect().top;
    
    //Si el container no esta al top, los ciruclos no se mueven con el scroll
    if (viewportPos < 0) {
        viewportPos = 0;
        
        //La maxima posicion de viewportPos es el height del container
    } else if (viewportPos >= container.getBoundingClientRect().height) {
        viewportPos = container.getBoundingClientRect().height;
        
        //Mientras el container esté en el viewport, moveremos los círculos al scroll
    } else if (viewportPos <= (container.getBoundingClientRect().height - window.innerHeight)) {
        if (circleInteraction != 'auto') moveCircles();
    }
    
}




function moveCircles (coordinates) {
    const duration = coordinates && circleInteraction == 'auto' 
        ? coordinates.duration : .5;
    const sourceY = coordinates && circleInteraction == 'auto' 
        ? window.innerHeight * coordinates.y // containerHeight
        : mouseY;
    const sourceX = coordinates && circleInteraction == 'auto' 
        ? (leftLimit * coordinates.x) + leftLimit 
        : mouseX;

    for (let i = circles; i > 0; i--) {
        //obtenemos un circulo
        const c = document.querySelector(`[data-circle="${i}"]`);
        const circleCenterHor = c.offsetWidth / 2;
        
        //seteamos el % de desfase segun el centro
        const centerOffsetX = (sourceX - centerX)/centerX;
        const centerOffsetY = ((sourceY - centerY)/centerY);
        
        //definimos los ejes X e Y de los circulos.
        //Movimiento en Y
        moveY = (sourceY - (circleCenterHor + (circleCenterHor * centerOffsetY))) + (viewportPos * .75);
        if (circleInteraction != 'auto') {
            if (moveY < 60) moveY = 60;
            else if (moveY > (containerHeight - 200)) moveY = containerHeight - 200;
        }
        
        //Movimiento en X
        if (sourceX <= wrapperMargin) {
            //areaX/100 es un número arbitrario
            moveX = leftLimit - ((areaX/100)*i);
            
        } else if(sourceX >= leftLimit && sourceX <= rightLimit){
            moveX = sourceX - (circleCenterHor + (circleCenterHor*centerOffsetX));
            
        } else if (sourceX >= rightLimit) {
            moveX = rightLimit - (circleCenterHor + (circleCenterHor*centerOffsetX));
            
        } else {
            moveX = leftLimit - (circleCenterHor + (circleCenterHor*centerOffsetX));
        }
        
        //Aplicamos las coordenadas
        gsap.to(c, {
            translateX: moveX, 
            translateY: moveY,
            translateZ: 8*i,
            duration: duration, 
            ease: "easeInOut",
            delay:.1
        });
    }
}


function topCircles(){
    
    for (var i = circles; i > 0; i--) {
        
        //obtenemos un circulo
        var c = document.querySelector(`[data-circle="${i}"]`);
        var centering = c.offsetWidth / 2;
        
        //definimos los ejes X e Y de los circulos.
        //Movimiento en Y
        moveY = -centering;
        moveX = -centering + (container.offsetWidth/2);
        
        gsap.to(c, {
            left: moveX, 
            top: moveY,
            translateZ: 8*i,
            duration: 0
        });
    }
}

export function initConcentricCircles () {
    if (container) {
        const anchoPantalla = window.innerWidth,
            altoPantalla = window.innerHeight;
        
        //Ajustamos areas sensibles en base a la reso de la pantalla.
        //En la implementacion final sacaría la defiinición del wrapper del IF y lo tomaría directamente del wrapper html de la página
        if (anchoPantalla >= 1440){
            circleSize = "L";
            circlePosition = "side";
            
        } else if ((anchoPantalla >= 992) && (anchoPantalla < 1440)){
            circleSize = "M";
            circlePosition = "side";
            
        } else if ((anchoPantalla >= 768) && (anchoPantalla < 992)){
            circleSize = "M";
            circlePosition = "side";
            
        } else {
            circleSize = "S";
            circlePosition = "top";
        }

        // Configuro tipo de interaccion
        if (anchoPantalla >= 768) {
            circleInteraction = isTouchDevice() ? "auto" : "follow";
            // circleInteraction = "auto";

        } else {
            circleInteraction = "static";
        }
        
        //Area sensible donde los circulos siguen al mouse
        const padding = parseInt(window.getComputedStyle(container.querySelector('.wrapper'), null)
                            .getPropertyValue('padding-left'));
        wrapper = container.offsetWidth - (padding * 2);
        areaX = (wrapper / 2) - padding;
        
        //Variables usadas en el seguimiento de los circulos para la funcion 'moveCircles'
        wrapperMargin = ((anchoPantalla - wrapper)/2);
        leftLimit = wrapperMargin + (wrapper - areaX);
        rightLimit = leftLimit + areaX;
        centerX = leftLimit + (areaX / 2);
        centerY = altoPantalla / 2;
        moveX = centerX;
        moveY = centerY;
        
        
        //Ajustamos el tamaño de los circulos
        setCircles();
        if (circleInteraction != 'static') centerCircles();
        
        
        //Interaccion de los circulos
        /*
        Aun no hice nada con esto, la idea es que:
        FOLLOW: sigue al mouse, el q anda por defecto.
        AUTO: se defina un movimiento animado para tablets o tactiles grandes
        STATIC: para tácitles chicos, lo que pasa ahora q se va al top y se queda quieto.
        */

        let autoInterval;
        let currentAutoStep = 0;
        const autoSteps = [
            { y: 0.48, x: 0.49, duration: 2 },
            { y: 0.78, x: 0.13, duration: 2 },
            { y: 0.80, x: 0.90, duration: 2 },
            { y: 0.21, x: 0.68, duration: 2 }
        ];
        
        switch (circleInteraction) {
            
            case "follow":
                window.addEventListener('mousemove', catchMouse);
                window.addEventListener('scroll', checkViewport);
                clearInterval(autoInterval);
                break;
            
            case "auto":
                window.removeEventListener('mousemove', catchMouse);
                window.addEventListener('scroll', checkViewport);
                checkViewport();
                autoInterval = setInterval(() => {
                    moveCircles(autoSteps[currentAutoStep]);
                    currentAutoStep = currentAutoStep == autoSteps.length - 1 ? 0 : ++currentAutoStep;
                }, 3500);
                break;
            
            case "static":
                window.removeEventListener('mousemove', catchMouse);
                window.removeEventListener('scroll', checkViewport);
                clearInterval(autoInterval);
                break;
            
        }
        
        //Posición de los circulos
        if (circlePosition == 'top') topCircles();
    }
}