export function caseHeaderBackgroundAnimation() {
    if (document.querySelector('.case-hero')) {
        const timming = 5;
        const timeline = gsap.timeline({ repeat: -1 });
        timeline.to(".case-hero", {
            duration: timming,
            ease: 'power2.inOut',
            backgroundPosition: '75% 10%'
        });
        timeline.to(".case-hero", {
            duration: timming,
            ease: 'power2.inOut',
            backgroundPosition: '30% 50%',
            backgroundSize: 'auto 200%'
        });
        timeline.to(".case-hero", {
            duration: timming,
            ease: 'power2.inOut',
            backgroundPosition: '50% 10%',
            backgroundSize: 'auto 120%'
        });
    }

    return false;
}