export function heightTitlePostDinamic() {
    const rows = document.querySelectorAll('.row-posts');
    if(rows){
        function setHeightTitlePost() { //Empareja la altura de posts paralelos
            rows.forEach(row => {
                let titles = row.querySelectorAll('.post__title')
                let heights = [];
                titles.forEach(title => {
                    heights.push(title.offsetHeight);
                })
                let maxHeight = Math.max(...heights);
                titles.forEach(title => {
                    title.style.height = maxHeight + 'px';
                })
            });
        }
        function setHeightTitlePostMobile() { //La altura se ajusta al contenido
            rows.forEach(row => {
                let titles = row.querySelectorAll('.post__title')
                titles.forEach(title => {
                    title.style.height = 'auto';
                })
            });
        }
        window.addEventListener('load',() => {
            if(window.innerWidth > 768){
                setHeightTitlePost();
            } else setHeightTitlePostMobile();
        })
        window.addEventListener('resize',() => {        
            if(window.innerWidth > 768){
                setHeightTitlePost();
            } else setHeightTitlePostMobile();
        } )
    }
}

export function heightTitleThreePostDinamic() {
    const rows = document.querySelectorAll('.three-posts');
    if(rows){
        function setHeightTitlePost() { //Empareja la altura de posts paralelos
            rows.forEach(row => {
                let titles = row.querySelectorAll('.post__title')
                let heights = [];
                titles.forEach(title => {
                    heights.push(title.offsetHeight);
                })
                let maxHeight = Math.max(...heights);
                titles.forEach(title => {
                    title.style.height = maxHeight + 'px';
                })
            });
        }
        function setHeightTitlePostMobile() { //La altura se ajusta al contenido
            rows.forEach(row => {
                let titles = row.querySelectorAll('.post__title')
                titles.forEach(title => {
                    title.style.height = 'auto';
                })
            });
        }
        window.addEventListener('load',() => {
            if(window.innerWidth > 768){
                setHeightTitlePost();
            } else setHeightTitlePostMobile();
        })
        window.addEventListener('resize',() => {        
            if(window.innerWidth > 768){
                setHeightTitlePost();
            } else setHeightTitlePostMobile();
        } )
    }
}

export function handlerStaticSubscribe() {
    const journal = document.querySelector('.hero-journal')
    if(journal){
        const subscribe = document.querySelector('.ego-form__subscribe')
        subscribe.classList.add('--show-static')
    }
}

export function hanldeBoxClass(){
    const threePosts = document.querySelectorAll('.three-posts')
    if(threePosts){
        function addBoxClass(){
            const posts = document.querySelectorAll('.three-posts__post')
            posts.forEach(post => {
                let postItem = post.querySelector('.post')
                postItem.classList.add('--box')
            })
        }
        function removeBoxClass(){
            const posts = document.querySelectorAll('.three-posts__post')
            posts.forEach(post => {
                let postItem = post.querySelector('.post')
                postItem.classList.remove('--box')
            })
        }
        window.addEventListener('load',() => {
            if(window.innerWidth <= 768){
                addBoxClass();
            }
        })
        window.addEventListener('resize',() => {
            if(window.innerWidth <= 768){
                addBoxClass();
            } else removeBoxClass();
        })
    }
}