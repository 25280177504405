import { getCookie } from "./tools";

export const getModalContent = async (modalUrl) => {
    const response = await fetch(modalUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/html',
            'x-requested-with': 'XMLHttpRequest',
            'X-CSRFToken': getCookie('csrftoken')
        }
    });
    if (response.status === 404) return false;
    return await response.text();
}