const ValidationMessages = {
    full_name: {
        empty: 'Name is required',
    },
    email: {
        empty: 'Email is required',
        invalid: 'Invalid Email',
    },
    message: {
        empty: 'Message is required',
    },
    password: {
        empty: 'Enter your password',
    },
    password_repeat: {
        empty: 'Repeat password',
        unequal: 'Passwords doesn\'t match',
    },
    cuil: {
        empty: 'Required field',
        min: 'Minimum 11 digits',
        invalid: 'Invalid number',
    },
    default: {
        empty: 'Required field'
    },
    url: {
        empty: 'Required field',
        invalid: 'This must be a valid URL.',
    },
};

export default ValidationMessages;