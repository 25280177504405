export function defineFooterTheme() {
    const sections = document.querySelectorAll('.section')
    const lastSection = sections?.[sections.length - 1]
    const footer = document.querySelector('.footer')
    const subscribe = document.querySelector('.ego-form__subscribe')

        if (lastSection?.classList.contains('--bg-black') && footer) {
        footer.classList.add('--bg-black');
            if(subscribe){
                subscribe.classList.add('--bg-white');
            }
        }
}

export function subscribeAnimation() {
    const footer = document.querySelector('.footer')
    const subscribe = document.querySelector('.ego-form__subscribe__content')

    if(subscribe){
        window.addEventListener('load', () => {
            let journal = document.querySelector('.hero-journal')
            if (footer && !journal) {
                gsap.to(footer, {
                    scrollTrigger: {
                        trigger: footer,
                        start: 'bottom bottom',
                        end: 'bottom bottom',
                        onEnter: () => {
                            subscribe.classList.add('--show')
                        },
                        onEnterBack: () => {
                            subscribe.classList.remove('--show')
                        },
                    },
                })
            }
        })
    }
}
