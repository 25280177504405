export function cultureManifestoAnimation() {
    if (document.querySelector(".to-manifesto_section")) {
        return gsap.fromTo(".to-manifesto_section", {
            clipPath: 'circle(0% at center)',
        }, {
            scrollTrigger: {
                trigger: ".to-manifesto_section",
                start: "-30% top",
                scrub: false,
                pin: false,
                once: true
            },
            duration: 0.5,
            ease: 'power2.out',
            clipPath: "circle(80% at center)",
        });
    }

    return false;
}

export function calculateStickyPlanetOffset() {
    const planetSection = document.querySelector('.planet_section');
    const planet = document.querySelector('.planet_img-vertical');
    if (planetSection && planet) {
        planet.style.top = `-${(planet.height - window.innerHeight) / 2.5}px`;
        window.addEventListener('resize', () => {
            planet.style.top = `-${(planet.height - window.innerHeight) / 2.5}px`;
        });
        gsap.to(planet, {
            scrollTrigger: {
                trigger: planet,
                start: 'top',
                end: 'bottom',
                scrub: true
            }, 
            y: (i, target) => -100,
            ease: "none"
        });
    }

}

export function inspiringForestIntro() {
    if (document.querySelector(".inspiring-forest__intro")) {
        return gsap.to(".inspiring-forest__intro", {
            scrollTrigger: {
                trigger: ".inspiring-forest__intro",
                start: "-40%",
                scrub: false,
                pin: false,
                once: true,
            },
            duration: 1.5,
            ease: 'power1.inOut',
            opacity: 1,
        });
    }

    return false;
}

export function inspiringForestCounter() {
    if (document.querySelector(".inspiring-forest__dots-forest__counter")) {
        const countTo = document.querySelector(".inspiring-forest__dots-forest__counter").dataset.count;
        const counter = gsap.to(".inspiring-forest__dots-forest__counter i", {
            scrollTrigger: {
                trigger: ".inspiring-forest__dots-forest__grid",
                start: "top 50%",
                end: "bottom 45%",
                scrub: true,
                pin: false
            },
            innerText: countTo,
            snap: "innerText"
        });
        gsap.registerPlugin(Observer);
        Observer.create({
            type: "wheel,touch,pointer",
            wheelSpeed: -1,
            onDown: () => counter.scrollTrigger.disable(false),
            onUp: () => counter.scrollTrigger.enable(false)
        });

        gsap.fromTo(".inspiring-forest__dots-forest__counter", {
            opacity: 0
        }, {
            scrollTrigger: {
                trigger: ".inspiring-forest__dots-forest__counter",
                start: "top 50%",
                end: "200px 75%",
                scrub: false,
                pin: false,
                toggleActions: "play none reverse none"
            },
            duration: 0.2,
            ease: 'power1.inOut',
            opacity: 1,
        });

        return true;
    }

    return false;
}

export function inspiringForestSliders() {
    if (document.querySelector(".inspiring-forest__sliders")) {
        const options = {
            loop: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }
        const inspiringForestSwiperLight = new Swiper(".inspiring-forest__sliders__slider.--light", {
            loop: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.--light .swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.--light .swiper-button-next',
                prevEl: '.--light .swiper-button-prev',
            },
        });
        const inspiringForestSwiperDark = new Swiper(".inspiring-forest__sliders__slider.--dark", {
            loop: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.--dark .swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.--dark .swiper-button-next',
                prevEl: '.--dark .swiper-button-prev',
            },
        });
    }
}

export function imgBoxToggle() {
    const boxes = document.querySelectorAll('.img-box');
    
    if (boxes.length > 0) {
        boxes.forEach(box => {
            box.addEventListener("click", e => {
                const clicked = e.currentTarget;
                const anchor = clicked.querySelector('.img-box-anchor');
                const arrow = clicked.querySelector('.img-box-anchor-img');
                
                clicked.classList.toggle('quote_visible');
                clicked.classList.toggle('img_visible');
                
                if(clicked.classList.contains('quote_visible')) {
                    anchor.style.textDecoration = 'underline'
                    arrow.style.opacity = 1
                } else if(clicked.classList.contains('img_visible')) {
                    anchor.style.textDecoration = 'none'
                    arrow.style.opacity = 0
                }
            });
            box.addEventListener("mouseleave", e => {
                const clicked = e.currentTarget;
                const anchor = clicked.querySelector('.img-box-anchor');
                const arrow = clicked.querySelector('.img-box-anchor-img');
                clicked.classList.add('img_visible');
                clicked.classList.remove('quote_visible');
                if (anchor) anchor.style.textDecoration = 'none'
                arrow.style.opacity = 0
            })
        })
    }
}


export function handleCertifiedBAnimation() {
    const box = document.querySelector(".certified-b__box");
    const logo = document.querySelector(".certified-b__logo-box");
    const text = document.querySelector(".certified-b__container .center-text");
    if (!(box && logo && text)) return;

    // Logo animation
    const logoAnimation = gsap.fromTo(
        ".certified-b-logo--mask",
        {
            scale: 8,
            zIndex: 10,
        },
        {
            scrollTrigger: {
                trigger: ".certified-b__container",
                start: "50%",
                once: true,
                scrub: false,
                markers: false,
            },
            duration: 0.65,
            scale: 0,
            zIndex: -1,
            ease: Power4.easeOut,
            onStart: () => {
                box.style.backgroundColor = "transparent";
                text.style.opacity = 1;
            },
        }
    );

    // Curtain effect in ONU grid
    const masks = gsap.utils.toArray(".onu-programme__thumb__mask--red");
    if (!masks.length > 0) return;

    const maskCurtain = masks.forEach((mask) => {
        return gsap.to(mask, {
            scrollTrigger: {
                trigger: mask,
                start: "40% bottom",
                once: true,
            },
            duration: 0.2,
            translateX: "-100%",
            ease: "linear",
        });
    });

    return { logoAnimation, maskCurtain };
}