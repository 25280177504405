import axios from 'axios';
import ValidationMessages from './validation-messages';
import { vanillaFade, getCookie, isElementVisible } from '../modules/tools';

export default class EgoForm {
    constructor({
        element,
        type, 
        submitType,
        showMessages,
        onSuccess, 
        onError,
        fieldGroups,
        serializerIgnoreList,
        resetOnSuccess,
        debug
    }) {
        this.form = element;
        this.type = type || 'contact';
        this.submitType = submitType || 'fetch';
        this.csrftoken = getCookie('csrftoken');
        this.showMessages = showMessages || false;
        this.actionUrl = this.form.getAttribute('action');
        this.fetchMethod = this.form.getAttribute('method') || 'POST';
        this.submitBtn = this.form.querySelector('button[type="submit"]');
        this.isValid = true;
        this.validationMessages = ValidationMessages;
        this.uploadProgressBar = this.form.querySelector('.ego-form__progress-bar') || null;
        this.onSuccess = onSuccess || null;
        this.onError = onError || null;
        this.successMessage = this.form.querySelector('.ego-form__message.--success') || null;
        this.errorMessage = this.form.querySelector('.ego-form__message.--error') || null;
        this.errorMessageDescription = this.errorMessage ? this.errorMessage.querySelector('.description') : null;
        this.fieldGroups = fieldGroups || null;
        this.hasFile = false;
        this.serializerIgnoreList = serializerIgnoreList || [];
        this.resetOnSuccess = resetOnSuccess || true;
        this.currentStep = this.form.querySelector('.ego-form__step') ? parseInt(this.form.querySelector('.ego-form__step.--active').dataset.step) : 0;
        this.currentStepOptional = false;
        this.stepChanging = false;
        this.debug = debug || false;


        this.declareHandlers();
        if (this.debug) this.showLog('initialized!');
    }

    submit() {
        if (this.debug) this.showLog(`submitting using ${this.submitType}!`);

        this.submittingForm(true);

        // Validate each required field
        this.isValid = true;
        this.form.querySelectorAll('.ego-form__field.--required,.ego-form__field.--validate-if-filled').forEach((field) => this.validateField(field) );

        if (!this.isValid) {
            this.submittingForm(false);
            if (this.debug) this.showLog(`there are invalid fields.`);
        }
        else {
            if (this.debug) {
                this.showLog(`the form was submitted!`);
                this.submittingForm(false);
                
                if (this.showMessages) {
                    vanillaFade({
                        element: this.successMessage, 
                        enter: true, 
                        time: 200,
                        displayType: 'flex'
                    });
                }
            }
            else {
                if (this.submitType == 'fetch') {
                    axios({
                        method: this.fetchMethod,
                        url: this.actionUrl,
                        headers: {
                            'Accept': 'application/json',
                            'X-CSRFToken': this.csrftoken
                        },
                        data: new FormData(this.form),
                        onUploadProgress: (p) => {
                            if (this.hasFile && this.uploadProgressBar) {
                                const per = Math.round((p.loaded * 100) / p.total);
                                this.uploadProgressBar.style.width = per + '%';
                            }
                        },
                    })
                    .then((resp) => {
                        if (resp.status === 200 || resp.status === 201) {
                            if (this.resetOnSuccess) this.reset();
                            if (this.showMessages && this.successMessage) {
                                vanillaFade({
                                    element: this.successMessage, 
                                    enter: true, 
                                    time: 200,
                                    displayType: 'flex'
                                });
                            }
                            if (typeof this.onSuccess == 'function') this.onSuccess(resp);
                        }
                        else {
                            this.displayError(resp);
                            if (typeof this.onError == 'function') this.onError(resp);
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR catch', err);
                        this.displayError(err);
                        if (typeof this.onError == 'function') this.onError(err);
                    })
                    .finally(() => {
                        this.submittingForm(false)
                        if (this.uploadProgressBar) this.uploadProgressBar.style.width = '0%';
                    });
                }
                else {
                    this.form.submit();
                }
            }
        }
    }

    submittingForm(sending) {
        let body = document.getElementsByTagName('body').item(0);
        if (sending) {
            this.submitBtn.classList.add('--loading');
            body.classList.add('--block');
        }
        else {
            this.submitBtn.classList.remove('--loading');
            body.classList.remove('--block');
        }
    }

    validateField(group) {
        const type = group.dataset.type,
            isRequired = group.classList.contains('--required'),
            errorElement = group.querySelector('.ego-form__field__error'),
            field = (type === 'radio') ? 
                group.querySelector('.ego-form__control:checked') 
                : group.querySelector('.ego-form__control'),
            fieldName = field ? field.getAttribute('name') : '';
        

        // Empty validation
        if (field) {
            if (type === 'single-checkbox' && !field.checked) {
                this.isValid = false;
                field.setAttribute('aria-invalid', 'true');
                group.classList.add('--has-error');
                if (errorElement) errorElement.innerText = this.validationMessages[ fieldName ] ? this.validationMessages[ fieldName ].empty : this.validationMessages.default.empty;
            } else {
                if (isRequired && field.value == '') {
                    this.isValid = false;
                    field.setAttribute('aria-invalid', 'true');
                    group.classList.add('--has-error');
                    if (errorElement) errorElement.innerText = this.validationMessages[ fieldName ] ? this.validationMessages[ fieldName ].empty : this.validationMessages.default.empty;
                }
                else if (field.value != '') {
                    // Custom validations
                    switch (type) {
                    case 'email':
                        if (!this.emailValidation(field.value)) {
                            this.isValid = false;
                            field.setAttribute('aria-invalid', 'true');
                            group.classList.add('--has-error');
                            if (errorElement) errorElement.innerText = this.validationMessages.email.invalid;
                        }
                        break;
                    
                    case 'password_repeat': {
                        const comparedTo = document.getElementById('password');
                        if (comparedTo && (field.value != comparedTo.value)) {
                            this.isValid = false;
                            field.setAttribute('aria-invalid', 'true');
                            group.classList.add('--has-error');
                            if (errorElement) errorElement.innerText = this.validationMessages.password_repeat.unequal;
                        }
                        break;
                    }
        
                    case 'cuil':
                    case 'cuit':
                        if (!this.cuitCuilValidation(field.value)) {
                            this.isValid = false;
                            field.setAttribute('aria-invalid', 'true');
                            group.classList.add('--has-error');
                            if (errorElement) errorElement.innerText = this.validationMessages.cuil.invalid;
                        }
                        break;
                    
                    case 'money':
                        const currency = field.dataset.currency ? field.dataset.currency : '$';
                        if (field.value == '' || field.value == currency) {
                            this.isValid = false;
                            field.setAttribute('aria-invalid', 'true');
                            group.classList.add('--has-error');
                            if (errorElement) errorElement.innerText = this.validationMessages.default.empty
                        }
                        break;
                    
                    case 'url':
                        if (!this.isValidUrl(field.value)) {
                            this.isValid = false;
                            field.setAttribute('aria-invalid', 'true');
                            group.classList.add('--has-error');
                            if (errorElement) errorElement.innerText = this.validationMessages.url.invalid
                        }
                        break;
                        
                    
                    default: break;
                    }
                }
            }
        }

        return this.isValid;
    }

    async displayError(error) {
        let errorMsg = (error instanceof Response) ? await error.json() : error, msg = '';
        for (const field in errorMsg) {
            if (Object.hasOwnProperty.call(errorMsg, field)) {
                msg = msg + errorMsg[ field ][ 0 ] + ' ';
            }
        }
        
        if (this.showMessages) {
            if (this.errorMessageDescription) this.errorMessageDescription.innerText = msg;

            try {
                vanillaFade({ element: this.errorMessage, enter: true, displayType: 'flex' });
            } catch (e) {
                throw new Error('Element \'errorMessage\' not found.');
            }
            return true;
        }

        return false;
    }

    collectData() {
        const formData = new FormData(this.form);
        const sendData = {};

        for (const pair of formData) {
            if (!this.serializerIgnoreList.includes(pair[0])) {
                if (pair[1] instanceof File && !pair[1].size && !pair[1].name) {
                    continue;
                } else {
                    sendData[pair[0]] = pair[1];
                }
            }
        }

        if (this.fieldGroups) {
            for (const groupName in this.fieldGroups) {
                if (Object.hasOwnProperty.call(this.fieldGroups, groupName)) {
                    let group = [{}];
                    for (const field of this.fieldGroups[ groupName ]) {
                        group[ 0 ][ field ] = formData.get(field);
                        delete sendData[ field ];
                    }
                    sendData[ groupName ] = group;
                }
            }
        }
        return sendData;
    }

    reset() {
        this.form.reset();
        this.form.querySelectorAll('.ego-form__field').forEach(field => 
            field.classList.remove('--filled', '--has-error')
        );
        this.form.querySelectorAll('.ego-form__control').forEach(field => 
            field.setAttribute('aria-invalid', 'false')
        );
    }

    fullReset() {
        this.reset();
        if (this.successMessage && isElementVisible(this.successMessage)) vanillaFade({ element: this.successMessage, enter: false });
        if (this.errorMessage && isElementVisible(this.errorMessage)) vanillaFade({ element: this.errorMessage, enter: false });
        if (this.currentStep) this.changeStep(1);
    }

    changeStep(step) {
        if (!this.stepChanging) {
            const current = this.currentStepOptional ? this.currentStep + 'b' : this.currentStep,
                currentElement = this.form.querySelector('[data-step="'+ current +'"]'),
                requiredFields = currentElement.querySelectorAll('.ego-form__field.--required,.ego-form__field.--validate-if-filled'),
                nextStepNumber = step === 'next' ? 
                        this.currentStep + 1 
                    : step === 'prev' && !this.currentStepOptional ? 
                        this.currentStep - 1
                    : step === 'optional' ?
                        this.currentStep + 'b'
                    : this.currentStep,
                nextElement = this.form.querySelector('[data-step="'+ nextStepNumber +'"]'); 

            if (this.currentStep !== nextStepNumber || this.currentStepOptional) {
                this.stepChanging = true;
                this.isValid = true;
                
                setTimeout(() => {
                    if (requiredFields && (step === 'next' || step === 'optional')) requiredFields.forEach((field) => this.validateField(field) );
        
                    if (currentElement && nextElement && this.isValid) {
                        vanillaFade({
                            element: currentElement, 
                            enter: false, 
                            time: 200,
                            displayType: 'flex',
                            callback: () => {
                                currentElement.classList.remove('--active');
                                vanillaFade({
                                    element: nextElement, 
                                    enter: true, 
                                    time: 200,
                                    displayType: 'flex',
                                    callback: () => {
                                        nextElement.classList.add('--active');
                                        this.stepChanging = false;
                                        this.currentStepOptional = step === 'optional';
                                        this.currentStep = parseInt(nextStepNumber);
                                    }
                                });
                            }
                        });
                    }
                    else this.stepChanging = false;
                }, 50);
            }
        }
    }

    nextStep() {
        this.changeStep('next');
    }

    optionalStep() {
        this.changeStep('optional');
    }

    prevStep() {
        this.changeStep('prev');
    }

    emailValidation(email) {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(email.toLowerCase());
    }

    cuitCuilValidation(num) {
        const multipliers = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        let digits = num.toString().split('');
    
        const validator = parseInt(digits.pop());
    
        digits.slice(-1);
        digits = digits.map(digit => parseInt(digit));
    
        if (multipliers.length !== digits.length) return false;
    
        let reduction = digits.reduce((carry, digit, index) => {
            return carry += digit * multipliers[ index ];
        }, 0);
    
        const division = (reduction / 11).toFixed(2);
        const decimals = division.split('.')[ 1 ].split('');
        const decimal = decimals[ 1 ] > 0 ? parseInt(decimals[ 0 ]) + 1 : parseInt(decimals[ 0 ]);
        const result = 11 - decimal;
    
        return validator === result;
    }

    isControlFilled(control) {
        let parent = control.parentElement;
        if(control.value !== '') {
            parent.classList.add('--filled');
        } else {
            parent.classList.remove('--filled');
        }
    }

    clearControlError(control) {
        control.setAttribute('aria-invalid', 'false');
        const field = control.parentElement,
            errorElement = field.querySelector('.ego-form__field__error');
        if (errorElement) errorElement.innerText = '';
        field.classList.remove('--has-error');
    }

    filterNumber(value) {
        let reg = /[^0-9]/g;
        return value.replace(reg, '');
    }

    filterMoneyAmount(num, currency = '$') {
        if (!num || num == currency) return '';
        let result = this.filterNumber(num)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `${currency} ${result}`;
    }
    
    filterPhoneNumber(value) {
        let reg = /[a-zA-Z*'";:\\/?!@#$%^&=_`~]/g;
        return value.replace(reg, '');
    }

    isValidUrl(urlString) {
        let urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    togglePasswordVisibility(btn) {
        const input = btn.parentElement.querySelector('.ego-form__control');
        if (input) {
            const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
            input.setAttribute('type', type);
            btn.classList.toggle('--hide');
        }
    }

    declareHandlers() {
        const self = this;
        if (this.submitBtn) {
            this.submitBtn.addEventListener('click', function(e) { 
                e.preventDefault();
                self.submit();
            });
        }
        else {
            throw new Error(`There's no submit button in this form "${this.form.id}".`);
        }

        this.form.querySelectorAll('.ego-form__next-step').forEach(element => {
            element.addEventListener('click', self.nextStep.bind(self));
        });

        this.form.querySelectorAll('.ego-form__optional-step').forEach(element => {
            element.addEventListener('click', self.optionalStep.bind(self));
        });

        this.form.querySelectorAll('.ego-form__prev-step').forEach(element => {
            element.addEventListener('click', self.prevStep.bind(self));
        });

        this.form.querySelectorAll('.ego-form__control')
            .forEach(element => {
                this.isControlFilled(element);

                element.addEventListener('keyup', () => {
                    this.isControlFilled(element);
                    // MASKS
                    if (element.name.slice(-5) === '_mask') {
                        element.parentElement.querySelector(`[name="${element.name.slice(0,-5)}"]`).value = this.filterNumber(element.value);
                    }
                });
                element.addEventListener('change', () => {
                    this.isControlFilled(element);
                    // MASKS
                    if (element.name.slice(-5) === '_mask') {
                        element.parentElement.querySelector(`[name="${element.name.slice(0,-5)}"]`).value = this.filterNumber(element.value);
                    }
                });

            });
        
        this.form.querySelectorAll('.ego-form__field__textarea')
            .forEach(element => {
                const counter = element.parentElement.querySelector('.ego-form__field__length-counter > i');
                const max = element.getAttribute('maxlength');
                element.addEventListener('keyup', () => {
                    counter.innerText = max - element.value.length;
                });

                element.addEventListener('paste', () => {
                    counter.innerText = max - element.value.length;
                });
            });
        
        this.form.querySelectorAll('.ego-form__control')
            .forEach(element => {
                element.addEventListener('focus', () => {
                    this.clearControlError(element);
                });
            });

        this.form.querySelectorAll('.ego-form__field[data-type="file"]')
            .forEach(field => {
                const input = field.querySelector('input[type="file"]'),
                    isInTextarea = field.classList.contains('ego-form__field__attachment'),
                    fileNameElement = field.querySelector('.attachment-file-name'),
                    fileSizeElement = field.querySelector('.attachment-file-size'),
                    errorElement = field.querySelector('.ego-form__field__error'),
                    removeBtn = field.querySelector('.attachment-remove'),
                    placeholder = field.dataset.placeholder,
                    maxFileSize = field.dataset.maxSize, //MB
                    minFileSizeErrorMessage = '<i>The selected file is empty.</i>',
                    maxFileSizeErrorMessage = `<i><strong>The file is too large</strong>, ${maxFileSize} MB max.</i>`;
    
                input.addEventListener('change', e => {
                    e.stopPropagation();
                    const fileName = e.target.value.split( '\\' ).pop(),
                        fullFileSize = input.files[0].size,
                        fileSize = input.files.length ? (fullFileSize / 1048576).toFixed(1) : 0; //MB
                    if (fileSize > maxFileSize || !fullFileSize) {
                        this.hasFile = false;
                        input.value = '';
                        field.classList.remove('--has-file');
                        fileNameElement.innerText = placeholder;
                        input.setAttribute('aria-invalid', 'true');
                        field.classList.add('--has-error');

                        if (isInTextarea) {
                            fileSizeElement.innerHTML = !fullFileSize ? minFileSizeErrorMessage : maxFileSizeErrorMessage;
                        } else {
                            errorElement.innerHTML = !fullFileSize ? minFileSizeErrorMessage : maxFileSizeErrorMessage;
                        }
                    }
                    else if (fileName) {
                        this.hasFile = true;
                        field.classList.add('--has-file');
                        fileSizeElement.innerHTML = `&#10004 ${fileSize}MB`;
                        fileNameElement.innerText = fileName;
                    }
                    else {
                        this.hasFile = false;
                        field.classList.remove('--has-file');
                        fileSizeElement.innerHTML = '';
                        fileNameElement.innerText = placeholder;
                    }
    
                });
    
                removeBtn.addEventListener('click', () => {
                    input.value = '';
                    field.classList.remove('--has-file');
                    fileSizeElement.innerHTML = '';
                    fileNameElement.innerText = placeholder;
                });
            });
        
        // Filter number input
        this.form.querySelectorAll('.ego-form__field.--number input')
            .forEach(element => {
                element.addEventListener('input', () => {
                    element.value = this.filterNumber(element.value);
                });
                element.addEventListener('paste', () => {
                    element.value = this.filterNumber(element.value);
                });
            });
        
        // Filter money input
        this.form.querySelectorAll('.ego-form__field.--money-amount input')
            .forEach(element => {
                const currency = element.dataset.currency ? element.dataset.currency : '$';
                element.addEventListener('input', () => {
                    element.value = this.filterMoneyAmount(element.value, currency);
                });
                element.addEventListener('paste', () => {
                    element.value = this.filterMoneyAmount(element.value, currency);
                });
            });

        // Filter phone input
        this.form.querySelectorAll('.ego-form__field.--phone input')
            .forEach(element => {
                element.addEventListener('input', () => {
                    element.value = this.filterPhoneNumber(element.value);
                });
                element.addEventListener('paste', () => {
                    element.value = this.filterPhoneNumber(element.value);
                });
            });
        
        this.form.querySelectorAll('.ego-form__message__close').forEach(btn => {
            btn.addEventListener('click', () => {
                vanillaFade({ element: btn.parentNode, enter: false });
            });
        });

        this.form.querySelectorAll('.ego-form__field__toggle-visibility').forEach(element => {
            element.addEventListener('click', this.togglePasswordVisibility(element));
        });
    }

    showLog(msg) {
        console.log('::EgoForm:: ' + msg);
    }
}