export function handleTextSliderSantander() {
    // SANTANDER CASE TEXT SLIDER //
    // Swiper.js config //

    const options = {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 15,
        autoplay: {
            delay: 0,
        },
        allowTouchMove: false,
        speed: 19000,
    };

    const textSwiperS = new Swiper(".swiper.swiper-text-santander", options);

    // Reverse slider
    const textSwiperReverseS = new Swiper(".swiper.swiper-text-santander--reverse", {
        ...options,
        autoplay: {
            delay: 0,
            reverseDirection: true,
        },
    });
}