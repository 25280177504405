import EgoForm from "../components/ego-form";

export const initModalForms = () => {
    const formElements = {
        hello: document.getElementById('helloForm'),
        proyect: document.getElementById('proyectForm'),
        partner: document.getElementById('partnerForm'),
        collaborate: document.getElementById('collaborateForm'),
        recruiting: document.getElementById('recruitingForm'),
    };


    if (formElements.hello) {
        const helloForm = new EgoForm({
            element: formElements.hello,
            showMessages: true,
        });
    }

    if (formElements.proyect) {
        const proyectForm = new EgoForm({
            element: formElements.proyect,
            showMessages: true,
        });
    }

    if (formElements.partner) {
        const partnerForm = new EgoForm({
            element: formElements.partner,
            showMessages: true,
        });
    }

    if (formElements.collaborate) {
        const collaborateForm = new EgoForm({
            element: formElements.collaborate,
            showMessages: true,
        });
    }

    if (formElements.recruiting) {
        const recruitingForm = new EgoForm({
            element: formElements.recruiting,
            showMessages: true,
        });
    }
}

export function initSubscribeForm(){
    const subscribe = document.getElementById('subscribeForm')
    if (subscribe) {
        const subscribeForm = new EgoForm({
            element: subscribe,
            showMessages: true,
        });
    }
}

export const searchableRadioButtonsGroup = () => {
    const groups = document.querySelectorAll('.ego-form__field.--radio.--searchable')
    groups.forEach(group => {
        const radios = group.querySelectorAll('.ego-form__field__radio');
        const empty = group.querySelector('.ego-form__field__search .empty');
        const options = radios.length;

        group.querySelector('.ego-form__field__search input')
            .addEventListener('input', e => {
                let count = 0;
                radios.forEach(radio => {
                    if (radio.dataset.value.toLowerCase().indexOf(e.target.value.toLowerCase()) === -1) {
                        radio.classList.add('--hidden');
                        radio.querySelector('.ego-form__control').checked = false;
                        ++count;
                    } else {
                        radio.classList.remove('--hidden');
                    }
                });
                if (count >= options) empty.classList.add('--show');
                else empty.classList.remove('--show');
            });
    });
}