import { wordTransition } from "./tools";

export function mottoAnimation() {
    const mottoElement = document.querySelector(".home-motto");
    if (mottoElement) {
        gsap.registerPlugin(ScrollToPlugin);
        return gsap.fromTo(".home-motto", {
            clipPath: "circle(0% at center)",
        }, {
            scrollTrigger: {
                trigger: ".home-motto",
                start: "-40%",
                scrub: false,
                pin: false,
                once: true,
                markers: false,
            },
            duration: 0.2,
            clipPath: "circle(80% at center)",
            onComplete: () => {
                const motto = document.querySelector(".motto-animation");
                if (!motto.classList.contains("--animate"))
                    motto.classList.add("--animate");
                const firstWordElement = document.querySelector(".first-word");
                const firstWordList = firstWordElement.dataset.list
                    .split(",")
                    .map((item) => item.trim());
                const secondWordElement =
                    document.querySelector(".second-word");
                const secondWordList = secondWordElement.dataset.list
                    .split(",")
                    .map((item) => item.trim());

                animateMottoWords({
                    element: firstWordElement,
                    list: firstWordList,
                    delay: 4000,
                });

                setTimeout(() => {
                    animateMottoWords({
                        element: secondWordElement,
                        list: secondWordList,
                        delay: 4000,
                    });
                }, 2000);
            },
        });
    }

    return false;
}

function animateMottoWords({ element, list, delay = 3000 }) {
    if (element) {
        let index = 0;
        setInterval(() => {
            wordTransition({
                element: element,
                newWord: list[((index % list.length) + list.length) % list.length],
            });
            if (index == list.length - 1) {
                element.classList.remove("--active");
                index = 0;
            }
            else {
                element.classList.add("--active");
                ++index;
            }
        }, delay);
    }
}

// SERVICES SLIDER ANIMATION //
export function handleSliderAnimationHome() {
    // Swiper.js config
    const delay = 2000;
    const speed = 200;
    const homeSwiper = new Swiper(".home-services__slider .swiper", {
        autoplay: {
            delay,
        },
        init: false,
        speed,
    });

    const sliderMask = document.querySelector(
        ".home-services__slider .mask-slider"
    );

    // Handle mouse enter
    function runSlider() {
        sliderMask.classList.add("--animated-init");
        setTimeout(function () {
            homeSwiper.init();
            homeSwiper.autoplay.start();
            sliderMask.style.backgroundColor = "transparent";
            sliderMask.classList.remove("--animated-init");
        }, speed);
    }

    // Handle mask animation
    homeSwiper && homeSwiper.on("reachEnd", function () {
        sliderMask.classList.remove("--animated-start");
        sliderMask.classList.add("--animated-end");
        setTimeout(function () {
            sliderMask.classList.remove("--animated-end");
            sliderMask.classList.add("--animated-start");
        }, delay);
    });

    // CAREERS SECTION - ANIMATION
    const careersMask = document.querySelector(".home-careers__thumb-mask");

    // ONU PROGRAMME SECTION
    const onuMask = document.querySelectorAll(".onu-programme__thumb__mask--white");

    // INTERSECTIONS OBSERVER TO RUN SLIDERS
    const threshold = window.matchMedia("(min-width: 992px)").matches ? [0.6] : [0.2];
    const observerSliderBox = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    runSlider();
                    observer.unobserve(entry.target);
                }
            });
        },
        { threshold }
    );

    const observerCareersThumb = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("--animated-init");
                    observer.unobserve(entry.target);
                }
            });
        },
        { threshold }
    );

    const observerOnuThumb = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("--animated-init");
                    observer.unobserve(entry.target);
                }
            });
        },
        { threshold }
    );

    sliderMask && observerSliderBox.observe(sliderMask);
    careersMask && observerCareersThumb.observe(careersMask);
    onuMask.length > 0 &&
        onuMask.forEach((thumb) => observerOnuThumb.observe(thumb));
}
