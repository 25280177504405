export function handleTextSlider() {
    const options = {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 15,
        autoplay: {
            delay: 0,
        },
        allowTouchMove: false,
        speed: 9500,
    };

    const textSwiper = new Swiper(".swiper.swiper-text", options);

    // Reverse slider
    const textSwiperReverse = new Swiper(".swiper.swiper-text--reverse", {
        ...options,
        autoplay: {
            delay: 0,
            reverseDirection: true,
        }
    });
}

export function handleCareersAnimation() {
    const circles = document.querySelector(".careers-circles");
    const right = document.querySelector(".right");

    if(right && circles) {
        gsap.to(right, {
            scrollTrigger: circles,
            duration: 0.5,
            x: "-75vw"
        });
    }
}

export function handlePeopleAreSaying(){
    const peopleAreSaying = document.querySelector(".people-are-saying__section");
    if(peopleAreSaying) {
        function addTargetBlank() {
            document.querySelectorAll(".people-are-saying__item-title a").forEach(link => {
                link.setAttribute("target", "_blank");
            });
        }
        function starsScore() { 
            document.querySelectorAll(".people-are-saying__score").forEach(star => {
                let score = parseFloat(star.querySelector("p").innerText);
                if(score > 5.0){
                    score = 5.0;
                    star.querySelector("p").innerText = score.toFixed(1);
                }
                const starScore = star.querySelector(".people-are-saying__star-score");
                const starPorcentage = `${score * 20}%`
                starScore.style.width = starPorcentage;
            });
        }
        addTargetBlank();
        starsScore();
    }

    if (peopleAreSaying && window.innerWidth <= 768) {
        const descriptionContainer = document.querySelectorAll(".--mobile .people-are-saying__item-description");

        function truncateDescriptions(descriptions, length) {
            descriptions.forEach(description => {
                const text = description.querySelector("p").innerText;
                if (text.length > length){
                    description.querySelector("p").innerText = text.substring(0, length) + "...";
                }
            });
        }

        truncateDescriptions(descriptionContainer, 187);
    }
}

export function handleTeamClimateAnimation(){
    const teamClimateSection = document.querySelector(".team-climate__section");

    if(teamClimateSection) {
        const likeEgoSquare = document.querySelector(".team-climate__square-grafic.--like-ego");
        const likeEgoScoreTextContainer = likeEgoSquare.querySelector(".team-climate__square-grafic__score");
        const likeEgoScoreText = likeEgoSquare.querySelector(".team-climate__square-grafic__score").querySelector("p")
        const recommendEgoSquare = document.querySelector(".team-climate__square-grafic.--recommend-ego")
        const recommendEgoScoreTextContainer = recommendEgoSquare.querySelector(".team-climate__square-grafic__score");
        const recommendEgoScoreText = recommendEgoSquare.querySelector(".team-climate__square-grafic__score").querySelector("p")


        function countDecimal(elementText, elementBackground, elementTextContainer, target, duration) {
            if(elementTextContainer.classList.contains("--ready")) return;
            elementTextContainer.classList.add('--ready')
            let current = 0;
            const step = 0.1;
            let canStartAnimating = false;

            const intervalId = setInterval(() => {
                elementBackground.style.backgroundImage = `linear-gradient(to right, #FFFF00 ${current * 10}%, #F1F1F1 0)`;
                elementText.innerText = current.toFixed(1);
                if (current >= 4.0) {
                    canStartAnimating = true;
                }
                if (canStartAnimating) {
                    elementTextContainer.style.width = `${current * 10}%`;
                }
                current += step;
                if (current.toFixed(1) > target) {
                    clearInterval(intervalId);
                }
            }, duration);
        }
        gsap.to(teamClimateSection, {
            scrollTrigger: {
                trigger: teamClimateSection,
                start: "top center",
                onEnter: () => {
                    countDecimal(likeEgoScoreText, likeEgoSquare, likeEgoScoreTextContainer, 9.3, 25)
                },
            }
        });
        gsap.to(teamClimateSection, {
            scrollTrigger: {
                trigger: teamClimateSection,
                start: "top center",
                onEnter: () => {
                    countDecimal(recommendEgoScoreText, recommendEgoSquare, recommendEgoScoreTextContainer, 9.4, 30)
                },
            }
        });

    }
}