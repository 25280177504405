import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

export const handlePrinciplesSlider = () => {
    if(document.querySelector('.slider-principles__slider')){
        const principlesSwiper = new Swiper('.slider-principles__slider.swiper.--principles', {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 2.5,
            allowTouchMove: true,
            
            pagination: {
                el: '.slider-principles__slider-text.--principles',
                type: 'fraction',
            },
    
            navigation: {
                nextEl: '.slider-principles__next-button.--principles',
                prevEl: '.slider-principles__previus-button.--principles',
            },
    
            breakpoints: {
                1440: {
                    spaceBetween: 40,
                    slidesPerView: 2.5,
                    allowTouchMove: false,
                },
                1024: {
                    spaceBetween: 20,
                    allowTouchMove: false,
                    slidesPerView: 2.5,
                    allowTouchMove: false,
                },
                768: {
                    spaceBetween: 20,
                    slidesPerView: 2.5,
                    allowTouchMove: true,
                },
                320: {
                    spaceBetween: 20,
                    slidesPerView: 1.5,
                    allowTouchMove: true,
                }
            }
        })
        const messagesSwiper = new Swiper('.slider-principles__slider.swiper.--messages', {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 2.5,
            allowTouchMove: true,
            
            pagination: {
                el: '.slider-principles__slider-text.--messages',
                type: 'fraction',
            },
    
            navigation: {
                nextEl: '.slider-principles__next-button.--messages',
                prevEl: '.slider-principles__previus-button.--messages',
            },
    
            breakpoints: {
                1440: {
                    spaceBetween: 40,
                    slidesPerView: 2.5,
                    allowTouchMove: false,
                },
                1024: {
                    spaceBetween: 20,
                    allowTouchMove: false,
                    slidesPerView: 2.5,
                    allowTouchMove: false,
                },
                768: {
                    spaceBetween: 20,
                    slidesPerView: 2.5,
                    allowTouchMove: true,
                },
                320: {
                    spaceBetween: 20,
                    slidesPerView: 1.5,
                    allowTouchMove: true,
                }
            }
        })

    }
}
